 .line {
  content: '';
  position: fixed;
  background: #303f9f;
  z-index: 105;
}

 .line.top {
  left: 0;
  top: 0;
  width: 100%;
  height: 30px;
}

@media (max-width: 840px) {
   .line.top {
    height: 15px;
  }

}

 .line.bottom {
  left: 0;
  top: auto;
  bottom: 0;
  width: 100%;
  height: 30px;
}

@media (max-width: 840px) {
   .line.bottom {
    height: 15px;
  }

}

 .line.left {
  left: 0;
  top: 0;
  width: 30px;
  height: 200%;
}

@media (max-width: 840px) {
   .line.left {
    width: 15px;
  }

}

 .line.right {
  left: auto;
  right: 0;
  top: 0;
  width: 30px;
  height: 200%;
}

@media (max-width: 840px) {
   .line.right {
    width: 15px;
  }

}
